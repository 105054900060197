import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import { H2 } from '@/components/Heading';
import NavLink from '@/components/UI/Links/NavLink';

interface BaseSectionProps {
    title: string;
    fullSiteLink?: string;
    fullSiteLinkLabel?: string;
}

export const BaseSection = (props: PropsWithChildren<BaseSectionProps>) => {
    const { children, fullSiteLink, fullSiteLinkLabel, title } = props;
    const intl = useIntl();

    return (
        <Box as="section" paddingY={6} paddingX={4} maxW="container.3xl" mx="auto">
            <Flex
                alignItems={{ md: 'center' }}
                rowGap={2}
                flexDirection={{ base: 'column', md: 'row' }}
                columnGap={4}
                justifyContent={{ md: 'space-between' }}
                marginBottom={6}
            >
                <H2 marginBottom={0}>{title}</H2>
                {fullSiteLink && (
                    <NavLink flexShrink={0} href={fullSiteLink} rightIcon={ChevronRightIcon}>
                        {fullSiteLinkLabel || intl.formatMessage({ id: 'see-more-news' })}
                    </NavLink>
                )}
            </Flex>
            {children}
        </Box>
    );
};
