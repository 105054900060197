import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { H3 } from '@/components/Heading';
import { NextImageBackground } from '@/components/Image';
import NavLink from '@/components/UI/Links/NavLink';

import { BannerProps } from '@/models/props/BannerProps';
import { StrapiUtilsService } from '@/services/StrapiUtilsService';
import { HtmlUtil } from '@/utils/HtmlUtil';
import { URLUtil } from '@/utils/URLUtil';

export const BannerCard = (props: BannerProps) => {
    const { attributes } = props;
    const [verDir, setVerDir] = useState('');
    const [horDir, setHorDir] = useState('');
    const [src, setSrc] = useState('');
    const imageUrl = attributes.image.data?.[0]?.attributes?.url;
    const pattern = /^((http|https):\/\/)/;
    const url = URLUtil.getURL(attributes.url);

    useEffect(() => {
        switch (attributes.textVerticalPosition) {
            case 'top':
                setVerDir('start');
                break;
            case 'bottom':
                setVerDir('end');
                BannerCard;
                break;
            case 'center':
                setVerDir('center');
                break;
            default:
                setVerDir('center');
                break;
        }
    }, [attributes.textVerticalPosition]);

    useEffect(() => {
        switch (attributes.textHorizontalPosition) {
            case 'left':
                setHorDir('start');
                break;
            case 'right':
                setHorDir('end');
                break;
            case 'center':
                setHorDir('center');
                break;
            default:
                setHorDir('center');
                break;
        }
    }, [attributes.textHorizontalPosition]);

    useEffect(() => {
        const image = imageUrl && StrapiUtilsService.updateUploadsUrl(imageUrl);

        setSrc(image);
    }, [src, imageUrl]);

    return (
        <NavLink
            href={url}
            display="flex"
            alignItems={verDir}
            justifyContent={horDir}
            overflow="hidden"
            position="relative"
            h="100%"
            p={5}
            data-group
            {...(pattern.test(attributes.url) && { target: '_blank' })}
            {...(pattern.test(attributes.url) && { rel: 'noopener noreferrer' })}
            _hover={{ textDecoration: 'none' }}
            _before={{
                content: '""',
                display: `${attributes.overlayColor ? 'block' : 'none'}`,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
                background: `${attributes.overlayColor ?? '#000000'}`,
                opacity: `${attributes.overlayOpacity ? attributes.overlayOpacity / 100 : 0}`,
                pointerEvents: 'none'
            }}
        >
            <H3
                as="h2"
                color={attributes.textColor ? attributes.textColor : 'grey.500'}
                display="flex"
                mb={0}
                position="relative"
                fontWeight="normal"
                zIndex={5}
            >
                <Box
                    color="grey.500"
                    dangerouslySetInnerHTML={{
                        __html: attributes.banner_text
                    }}
                />
            </H3>
            {src && (
                <NextImageBackground
                    src={src}
                    width={700}
                    height={450}
                    transition="all 200ms"
                    alt={HtmlUtil.getRawText(attributes.banner_text)}
                />
            )}
        </NavLink>
    );
};
