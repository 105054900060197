import { Flex, List, ListItem, SimpleGrid, useMediaQuery } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import SingleArticleCard from '@/components/Card/SingleArticleCard';

import breakpoints from '@/../themes/default-theme/breakpoints';
import RoutePath from '@/constants/route-path';
import { BaseSection } from '@/layouts/BaseSection/BaseSection';
import { NewsCarousel } from '@/layouts/NewsSection/SingleArticleCard';
import { NewsCardProps } from '@/models/props/NewsArticleProps';

export const NewsSection = (props: NewsCardProps) => {
    const { news, sectionTite, id } = props;
    const { data } = news;
    const intl = useIntl();
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });
    const [desktop, setDesktop]: [
        boolean | undefined,
        Dispatch<SetStateAction<boolean | undefined>>
    ] = useState();
    const title = intl.formatMessage({ id: sectionTite });
    const seeMoreNewsLinkLabel = useRef(intl.formatMessage({ id: 'see-more-news' }));
    const newsCards = data?.map((item, index) => {
        return (
            <Flex as={ListItem} key={index}>
                <SingleArticleCard article={item} id={id} />
            </Flex>
        );
    });

    useEffect(() => {
        setDesktop(isDesktop);
    }, [isDesktop]);

    return (
        <>
            {newsCards?.length > 0 && (
                <BaseSection
                    title={title}
                    fullSiteLink={RoutePath[id]}
                    fullSiteLinkLabel={seeMoreNewsLinkLabel.current}
                >
                    {!desktop ? (
                        <NewsCarousel news={news} sectionTite={sectionTite} id={id} />
                    ) : (
                        <SimpleGrid as={List} gap={4} columns={3}>
                            {newsCards}
                        </SimpleGrid>
                    )}
                </BaseSection>
            )}
        </>
    );
};
