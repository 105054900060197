import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export const SponsorPopup = () => {
    const { isOpen, onClose } = useDisclosure({
        defaultIsOpen: true
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <Box textAlign="center">
                        <InfoOutlineIcon color="brand.main" fontSize="5xl" mt={6} mb={4} />
                    </Box>
                    <Text>
                        <FormattedMessage id="sponsor-popup.message" />
                    </Text>
                </ModalBody>

                <ModalFooter justifyContent="center">
                    <Button colorScheme="brand" onClick={onClose}>
                        <FormattedMessage id="close" />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
