import { Box } from '@chakra-ui/react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';

import { ProductCard } from '@/components/Card/ProductCard';

import { CategoryProductListProps } from '@/models/props/ProductCardProps';
import breakpoints from '@/utils/Breakpoints';

const ProductsCarousel = (props: Pick<CategoryProductListProps, 'items'>) => {
    const { items } = props;
    const productsCards = items.map((item) => {
        return (
            <SwiperSlide key={item.code}>
                <ProductCard
                    code={item.code}
                    favourite={item.favourite}
                    slug={item.slug}
                    categories={item.categories}
                    currency={item.currency}
                    discount={item.discount}
                    grossPrice={item.grossPrice}
                    grossPriceBeforeDiscount={item.grossPriceBeforeDiscount}
                    lowestPriceIn30Days={item.lowestPriceIn30Days}
                    isNew={item.new}
                    mainImage={item.mainImage}
                    maximalProductCountInOrder={item.maximalProductCountInOrder}
                    netPrice={item.netPrice}
                    netPromoPrice={item.netPromoPrice}
                    netUnitPrice={item.netUnitPrice}
                    points={item.points}
                    productAvailableForSale={item.productAvailableForSale}
                    sale={item.sale}
                    title={item.title}
                    unit={item.unit}
                    shortDescription={item.shortDescription}
                    showDescription={true}
                />
            </SwiperSlide>
        );
    });

    return (
        <>
            <style global jsx>
                {`
                    :root {
                        --swiper-navigation-sides-offset: -16px;
                        --swiper-navigation-size: 1.25rem;
                        --swiper-navigation-color: #000;
                    }
                    .swiper {
                        display: flex;
                    }
                    .swiper-wrapper {
                        height: inherit;
                    }
                    .swiper-slide {
                        display: flex;
                    }
                    .swiper-button-prev,
                    .swiper-button-next {
                        background-color: #ffffff;
                        display: flex;
                        height: 3.25rem;
                        width: 3.8125rem;
                        overflow: hidden;
                        border: 1px solid #b0814b;
                    }
                    .swiper-button-prev.swiper-button-disabled,
                    .swiper-button-next.swiper-button-disabled {
                        opacity: 0;
                    }
                    .swiper-button-next::after {
                        content: ${String.raw`'\279C'`};
                    }
                    .swiper-button-prev::after {
                        content: ${String.raw`'\279C'`};
                        transform: rotate(180deg);
                    }
                `}
            </style>
            <Box pos="relative" mx={-4}>
                <Box width="full" px={5} overflow="hidden">
                    <Swiper
                        modules={[Navigation]}
                        navigation={true}
                        spaceBetween={20}
                        style={{ overflow: 'visible' }}
                        breakpoints={{
                            [breakpoints.xs]: {
                                slidesPerView: 1
                            },
                            [breakpoints.sm]: {
                                slidesPerView: 2
                            },
                            [breakpoints.md]: {
                                slidesPerView: 3
                            },
                            [breakpoints.xl]: {
                                slidesPerView: 4
                            }
                        }}
                    >
                        {productsCards}
                    </Swiper>
                </Box>
            </Box>
        </>
    );
};

export default ProductsCarousel;
